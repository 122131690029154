/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"fotopag"}>
        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column name={"lvqcuzz0yy"} style={{"paddingTop":12,"paddingBottom":29.1328125}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box ff--3" content={"Chorvatsko - ostrov Pag"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"pelfvooijes"} style={{"paddingTop":8}} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/7a975d3f33064318853dede1085f6e90_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/7a975d3f33064318853dede1085f6e90_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/7a975d3f33064318853dede1085f6e90_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/7a975d3f33064318853dede1085f6e90_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/df6086803876427590522314ae234f72_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/df6086803876427590522314ae234f72_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/df6086803876427590522314ae234f72_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/df6086803876427590522314ae234f72_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/df6086803876427590522314ae234f72_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/26e00c40c2064dddb66da2c4355159da_s=860x_.JPG"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/26e00c40c2064dddb66da2c4355159da_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13078/26e00c40c2064dddb66da2c4355159da_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13078/26e00c40c2064dddb66da2c4355159da_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13078/26e00c40c2064dddb66da2c4355159da_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13078/26e00c40c2064dddb66da2c4355159da_s=2000x_.JPG 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/141e34b20d314d74bcd664c9e6469289_s=860x_.JPG"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/141e34b20d314d74bcd664c9e6469289_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13078/141e34b20d314d74bcd664c9e6469289_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13078/141e34b20d314d74bcd664c9e6469289_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13078/141e34b20d314d74bcd664c9e6469289_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13078/141e34b20d314d74bcd664c9e6469289_s=2000x_.JPG 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":19.7421875}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/4dc4f2ba05274964a1792f35a6efdb31_s=860x_.JPG"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/4dc4f2ba05274964a1792f35a6efdb31_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13078/4dc4f2ba05274964a1792f35a6efdb31_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13078/4dc4f2ba05274964a1792f35a6efdb31_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13078/4dc4f2ba05274964a1792f35a6efdb31_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13078/4dc4f2ba05274964a1792f35a6efdb31_s=2000x_.JPG 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/80eebad5206c44a0bd2c457198c17f18_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/80eebad5206c44a0bd2c457198c17f18_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/80eebad5206c44a0bd2c457198c17f18_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/80eebad5206c44a0bd2c457198c17f18_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/80eebad5206c44a0bd2c457198c17f18_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/89dd63671f5c42d6b214e72f31fd035c_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/89dd63671f5c42d6b214e72f31fd035c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/89dd63671f5c42d6b214e72f31fd035c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/89dd63671f5c42d6b214e72f31fd035c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/89dd63671f5c42d6b214e72f31fd035c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/89dd63671f5c42d6b214e72f31fd035c_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/2af63e14cded4313b9e69aa3b374f414_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/2af63e14cded4313b9e69aa3b374f414_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/2af63e14cded4313b9e69aa3b374f414_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/2af63e14cded4313b9e69aa3b374f414_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":19.7421875}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/496815fd0641442983d54edd8834d71e_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/496815fd0641442983d54edd8834d71e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/496815fd0641442983d54edd8834d71e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/496815fd0641442983d54edd8834d71e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/496815fd0641442983d54edd8834d71e_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/ad11d2a06ca14fb6bc5908b5f9b7e83e_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/ad11d2a06ca14fb6bc5908b5f9b7e83e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ad11d2a06ca14fb6bc5908b5f9b7e83e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/ad11d2a06ca14fb6bc5908b5f9b7e83e_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/d850aeb9afc54be5a226fd03c4de1de9_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/d850aeb9afc54be5a226fd03c4de1de9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/d850aeb9afc54be5a226fd03c4de1de9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/d850aeb9afc54be5a226fd03c4de1de9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/d850aeb9afc54be5a226fd03c4de1de9_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/d850aeb9afc54be5a226fd03c4de1de9_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/b5c6f657d9e54bd6ae7b5e72d2e96999_s=860x_.JPG"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/b5c6f657d9e54bd6ae7b5e72d2e96999_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13078/b5c6f657d9e54bd6ae7b5e72d2e96999_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13078/b5c6f657d9e54bd6ae7b5e72d2e96999_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13078/b5c6f657d9e54bd6ae7b5e72d2e96999_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13078/b5c6f657d9e54bd6ae7b5e72d2e96999_s=2000x_.JPG 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":19.7421875}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/6108995db54644c4b5188321c7880e07_s=860x_.JPG"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/6108995db54644c4b5188321c7880e07_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13078/6108995db54644c4b5188321c7880e07_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13078/6108995db54644c4b5188321c7880e07_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13078/6108995db54644c4b5188321c7880e07_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13078/6108995db54644c4b5188321c7880e07_s=2000x_.JPG 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/521fb629d1f34dd49f6bd7ac34e529b0_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/521fb629d1f34dd49f6bd7ac34e529b0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/521fb629d1f34dd49f6bd7ac34e529b0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/521fb629d1f34dd49f6bd7ac34e529b0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/521fb629d1f34dd49f6bd7ac34e529b0_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/521fb629d1f34dd49f6bd7ac34e529b0_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/fd7612162f0e4e96b4c80a48b81b3bcb_s=860x_.JPG"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/fd7612162f0e4e96b4c80a48b81b3bcb_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13078/fd7612162f0e4e96b4c80a48b81b3bcb_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13078/fd7612162f0e4e96b4c80a48b81b3bcb_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13078/fd7612162f0e4e96b4c80a48b81b3bcb_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13078/fd7612162f0e4e96b4c80a48b81b3bcb_s=2000x_.JPG 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/26656eb49d9a4bd79295543a7b477415_s=860x_.JPG"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/26656eb49d9a4bd79295543a7b477415_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13078/26656eb49d9a4bd79295543a7b477415_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13078/26656eb49d9a4bd79295543a7b477415_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13078/26656eb49d9a4bd79295543a7b477415_s=1400x_.JPG 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":19.7421875}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/f4bddda9fbf34def8c759f1980fa3286_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/f4bddda9fbf34def8c759f1980fa3286_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/f4bddda9fbf34def8c759f1980fa3286_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/f4bddda9fbf34def8c759f1980fa3286_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/77fbf4c9a4fb4ce2afce735f1244ccdb_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/77fbf4c9a4fb4ce2afce735f1244ccdb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/77fbf4c9a4fb4ce2afce735f1244ccdb_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/5fd19a6eff9b4399ad0aa60d04aa75eb_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/5fd19a6eff9b4399ad0aa60d04aa75eb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/5fd19a6eff9b4399ad0aa60d04aa75eb_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/2ece4646c77a45cdb88a2f909d7d8517_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/2ece4646c77a45cdb88a2f909d7d8517_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/2ece4646c77a45cdb88a2f909d7d8517_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/2ece4646c77a45cdb88a2f909d7d8517_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":19.7421875}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/55629e893bcc4a79abaa6269428f1de4_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/55629e893bcc4a79abaa6269428f1de4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/55629e893bcc4a79abaa6269428f1de4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/55629e893bcc4a79abaa6269428f1de4_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/9bef4925d2004593a3b214a4658b2f36_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/9bef4925d2004593a3b214a4658b2f36_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/0cabc85a7d2e4021b18c4656cfb47fc8_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/0cabc85a7d2e4021b18c4656cfb47fc8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/0cabc85a7d2e4021b18c4656cfb47fc8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/0cabc85a7d2e4021b18c4656cfb47fc8_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/450f65249f85451abcd5201fbad84f41_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/450f65249f85451abcd5201fbad84f41_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/450f65249f85451abcd5201fbad84f41_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/450f65249f85451abcd5201fbad84f41_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}